import { getAPIerrorMessageByCode } from './helpers'

const JSONAPI_MIME = 'application/vnd.api+json'

function serializeQuery(query) {
  if (!query) {
    return ''
  }

  const keys = Object.keys(query)

  if (keys.length === 0) {
    return ''
  }

  return keys
    .map(key => {
      const value = query[key]

      const encodedKey = encodeURIComponent(key)

      if (value === null || value === undefined) {
        return encodedKey
      } else {
        const encodedValue = encodeURIComponent(value)
        return `${encodedKey}=${encodedValue}`
      }
    })
    .join('&')
}

export class JsonApiError extends Error {
  constructor(...errors) {
    super()
    this.constructor = JsonApiError
    this.__proto__ = JsonApiError.prototype
    this.message = getAPIerrorMessageByCode(errors)
    this.errors = errors
  }
}

class JsonHttp {
  static Error = JsonApiError

  constructor({ baseUrl }) {
    this.baseUrl = baseUrl
  }

  async request(path, { method, query, body, headers = {} }) {
    const url = `${this.baseUrl}/${path}?${serializeQuery(query)}`
    const access_token = localStorage.getItem('access_token')

    const config = {
      method,
      // credentials: 'include', temporary add to comment, for development
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': JSONAPI_MIME,
        ...headers,
      },
    }

    if (body && config.headers['Content-Type'] === JSONAPI_MIME) {
      config.body = JSON.stringify(body)
    } else {
      config.body = body
    }

    const response = await fetch(url, config)

    const responseContentType = response.headers.get('Content-Type')
    const isJsonContent =
      responseContentType && responseContentType.includes(JSONAPI_MIME)
    const responseBody = await (isJsonContent ? response.json() : response)

    const isOk = response.status >= 200 && response.status <= 299

    if (!isOk) {
      throw new JsonApiError(...responseBody.errors)
    }

    return isJsonContent ? responseBody.data : responseBody
  }

  get(path, { query, headers } = {}) {
    return this.request(path, { query, method: 'GET', headers })
  }

  post(path, { query, body, headers } = {}) {
    return this.request(path, { query, body, method: 'POST', headers })
  }

  put(path, { query, body, headers } = {}) {
    return this.request(path, { query, body, method: 'PUT', headers })
  }

  patch(path, { query, body, headers } = {}) {
    return this.request(path, { query, body, method: 'PATCH', headers })
  }

  delete(path, { query, headers } = {}) {
    return this.request(path, { query, method: 'DELETE', headers })
  }
}

export default JsonHttp
