import React, { useState, Fragment } from 'react'
import { Query } from 'react-admin'
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  IconButton,
  withStyles,
  Menu,
  MenuItem,
  CircularProgress,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import MarketEditModal from './MarketEditModal'
import MarketDeleteModal from '../shared/DeleteModal'
import MarketHideModal from './MarketHideModal'

import styles from './styles'

const MarketItem = props => {
  const { item, classes, resource } = props
  const [anchor, setAnchor] = useState(null)
  const [editModalOpened, setEditModalOpened] = useState(false)
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const [hideModalOpened, setHideModalOpened] = useState(false)
  const isMenuOpened = Boolean(anchor)

  const handleClickMenu = event => {
    setAnchor(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchor(null)
  }

  const handleEdit = () => {
    setEditModalOpened(true)
    setAnchor(null)
  }

  // const handleHide = () => {
  //   setHideModalOpened(true)
  //   setAnchor(null)
  // }

  const handleDelte = () => {
    setDeleteModalOpened(true)
    setAnchor(null)
  }

  const handleCloseModal = () => {
    setEditModalOpened(false)
    setDeleteModalOpened(false)
    setHideModalOpened(false)
  }

  return (
    <Fragment>
      <Card className={classes.card}>
        <CardHeader
          action={
            <Fragment>
              <IconButton
                aria-owns={anchor ? 'action-menu' : null}
                aria-haspopup="true"
                onClick={handleClickMenu}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="action-menu"
                anchorEl={anchor}
                open={isMenuOpened}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                {/* <MenuItem onClick={handleHide}>Hide</MenuItem> --temporary hide, waiting on backend changes--*/}
                <MenuItem onClick={handleDelte} hidden>
                  Delete
                </MenuItem>
              </Menu>
            </Fragment>
          }
          className={classes.headeText}
          title={item.name}
        />
        <CardContent className={classes.content}>
          <Query type="GET_STORE_ITEM_IMAGE" resource={resource} payload={item}>
            {({ loading, error }) => {
              if (loading || !item.preview) {
                return (
                  <div className={classes.media}>
                    <CircularProgress
                      size={24}
                      className={classes.imageProgress}
                    />
                  </div>
                )
              }
              if (error) {
                return <p>No image</p>
              }
              return (
                <CardMedia className={classes.media} image={item.preview} />
              )
            }}
          </Query>
        </CardContent>
      </Card>
      {editModalOpened && (
        <MarketEditModal
          isOpen={editModalOpened}
          handleClose={handleCloseModal}
          itemData={item}
          isLoading={props.isLoading}
          resource={props.resource}
        />
      )}
      {deleteModalOpened && (
        <MarketDeleteModal
          isOpen={deleteModalOpened}
          handleClose={handleCloseModal}
          itemData={item}
          isLoading={props.isLoading}
          titleText={`Are you sure you want to delete ${item.name}?`}
          resource={props.resource}
        />
      )}
      {hideModalOpened && (
        <MarketHideModal
          isOpen={hideModalOpened}
          handleClose={handleCloseModal}
          itemData={item}
          isLoading={props.isLoading}
          resource={props.resource}
        />
      )}
    </Fragment>
  )
}

export default withStyles(styles)(MarketItem)
