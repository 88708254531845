import mappingApiErrors from './mappingApiErrors'

function getAPIerrorMessageByCode(errors) {
  let errorMessage = ''
  if (Array.isArray(errors)) {
    errors.reverse().forEach((error, index) => {
      const { code } = error
      errorMessage += `${index > 0 ? '  ' : ''}${mappingApiErrors[code]}`
    })
  }
  return errorMessage
}

const readUploadedImgAsBase64 = async inputFile => {
  return new Promise((resolve, reject) => {
    const temporaryFileReader = new FileReader()
    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result)
    }

    temporaryFileReader.onerror = reject
    temporaryFileReader.readAsDataURL(inputFile)
  })
}

export { getAPIerrorMessageByCode, readUploadedImgAsBase64 }
