import { AUTH_CHECK, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR } from 'react-admin'

function createAuthProvider(api, { logging = false } = {}) {
  const requestTypeMap = {
    [AUTH_CHECK]: api.checkAccess,
    [AUTH_LOGIN]: api.signIn,
    [AUTH_LOGOUT]: api.signOut,
    [AUTH_ERROR]: () => Promise.resolve(),
  }
  return function authProvider(type, params) {
    if (logging) {
      console.info({ type, params })
    }
    const handler = requestTypeMap[type].bind(api)
    return handler(params)
  }
}

export default createAuthProvider
