import React from 'react'
import { Resource } from 'react-admin'
import firmwareIcon from '@material-ui/icons/SettingsApplications'

import FirmwareList from './components/Firmware/FirmwareList'

function FirmwareOriginal(props) {
  return (
    <Resource
      {...props}
      list={FirmwareList}
      options={{ label: 'Firmware', productType: 'original' }}
      icon={firmwareIcon}
    />
  )
}

export default FirmwareOriginal
