import React from 'react'
import PropTypes from 'prop-types'
import { withDataProvider, DELETE, showNotification } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import { Modal, Grid, Button, CircularProgress } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

const styles = theme => ({
  modal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30% !important',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  titleContainer: {
    textAlign: 'center',
  },
  title: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  inputContiner: {
    display: 'flex',
    flexDirection: 'column',
  },
  deleteIconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  deleteIcon: {
    height: 'auto',
    width: '60%',
    color: '#f55f5f',
  },
  messageText: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '50px',
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const DeleteModal = props => {
  const {
    classes,
    isOpen,
    handleClose,
    itemData,
    titleText,
    isLoading,
    dataProvider,
    dispatch,
  } = props

  const handleDelete = () => {
    dataProvider(DELETE, props.resource, itemData, {
      onSuccess: {
        refresh: true,
      },
    })
      .then(() => {
        dispatch(showNotification('Successfully deleted', 'info'))
        handleClose()
      })
      .catch(e => {
        dispatch(
          showNotification(`Error: Item is not deleted. ${e}`, 'warning'),
        )
        handleClose()
      })
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classes.modal}>
        <Grid item xs={12} className={classes.titleContainer}>
          <span className={classes.title}>{titleText}</span>
        </Grid>
        <br />
        <div className={classes.deleteIconContainer}>
          <Delete className={classes.deleteIcon} />
        </div>
        <br />
        <div className={classes.messageText}>This actions cannot be undone</div>
        <Grid item xs={12} container justify="space-between">
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            disabled={isLoading}
          >
            Delete
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Grid>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  itemData: PropTypes.object.isRequired,
}

export default withDataProvider(withStyles(styles)(DeleteModal))
