import React, { Fragment } from 'react'
import { List } from 'react-admin'

import { withStyles } from '@material-ui/core'

import ActionBar from '../shared/ActionBar'
import MarketItemsContainer from './MarketItemsContainer'

const styles = () => ({
  marketItemsList: {
    padding: '10px',
  },
})

const MarketItemsList = props => {
  const { options, classes } = props

  return (
    <Fragment>
      <ActionBar productType={options.productType} resourceTag="store-item" />
      <List
        title="Market"
        {...props}
        exporter={false}
        pagination={null}
        sortable={null}
        actions={null}
        className={classes.marketItemsList}
      >
        <MarketItemsContainer />
      </List>
    </Fragment>
  )
}

export default withStyles(styles)(MarketItemsList)
