import Endpoint from './Endpoint'

class Firmware extends Endpoint {
  fetchAll({ product }) {
    return this.http.get('firmware', {
      headers: {
        'Pix-Product': product,
      },
      query: {
        noCache: true,
      },
    })
  }

  upload({ files, version, product }) {
    return this.http.put(`firmware/${version}`, {
      body: files,
      headers: {
        'Content-Type': 'application/octet-stream',
        'Pix-Product': product,
      },
    })
  }

  delete({ version, product }) {
    return this.http.delete(`firmware/${version}`, {
      headers: {
        'Pix-Product': product,
      },
      query: {
        noCache: 'true',
      },
    })
  }
}

export default Firmware
