import React, { useState, Fragment } from 'react'

import {
  Card,
  Popover,
  Typography,
  CardContent,
  IconButton,
} from '@material-ui/core'
import { Delete, Info } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

const ImagePreviewContainer = props => {
  const {
    classes,
    product,
    files,
    availableTypes,
    checkImgParameters,
    removeFile,
    open,
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const popoverOpen = Boolean(anchorEl)

  const closePopover = () => {
    setAnchorEl(null)
  }

  const openPopover = e => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <div className={classes.imagePreviewContainer}>
      {files.map((file, index) => (
        <Fragment>
          <Fragment key={`upload_img_preview_${index}`}>
            {availableTypes.indexOf(file.type) !== -1 && (
              <Card>
                <CardContent
                  className={
                    !file.isValid
                      ? `${classes.imagesContainer} ${classes.errorStyle}`
                      : classes.imagesContainer
                  }
                >
                  <div className={classes.cardContent}>
                    <img
                      className={classes.imagePreview}
                      src={`${file.preview}#trick=${product}`} //trick to force onload
                      alt="img_preview"
                      onLoad={() => checkImgParameters(index)}
                    />
                    {!file.isValid && (
                      <Fragment>
                        <IconButton
                          className={classes.previewInfoButton}
                          aria-owns={open ? 'preview-info' : null}
                          aria-haspopup="true"
                          onClick={openPopover}
                        >
                          <Info />
                        </IconButton>
                        <Popover
                          id="preview-info"
                          open={popoverOpen}
                          anchorEl={anchorEl}
                          onClose={closePopover}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Typography className={classes.popoverMessage}>
                            {file.validationMessage}
                          </Typography>
                        </Popover>
                      </Fragment>
                    )}
                    <IconButton
                      onClick={() => removeFile(index)}
                      className={classes.previewDeteteButton}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </CardContent>
              </Card>
            )}
          </Fragment>
        </Fragment>
      ))}
    </div>
  )
}

export default withStyles(styles)(ImagePreviewContainer)
