import { GET_LIST, CREATE, UPDATE, DELETE } from 'react-admin'

const GET_STORE_ITEM_IMAGE = 'GET_STORE_ITEM_IMAGE'

function createStoreItemProvider(api, headerParams) {
  const requestTypeMap = {
    [GET_LIST]: () => api.fetchAllItems(headerParams),
    [CREATE]: payload => api.addItem(payload, headerParams),
    [UPDATE]: payload => api.updateItem(payload, headerParams),
    [DELETE]: payload => api.deleteItem(payload, headerParams),
    [GET_STORE_ITEM_IMAGE]: payload =>
      api.getStoreItemImage(payload, headerParams),
  }
  return function storeItemProvider(type, params) {
    const handler = requestTypeMap[type].bind(api)
    return handler(params)
  }
}

export default createStoreItemProvider
