import Endpoint from './Endpoint'

import { readUploadedImgAsBase64 } from '../helpers'

class Market extends Endpoint {
  fetchAllItems({ product }) {
    return this.http.get('store-item', {
      headers: {
        'Pix-Product': product,
      },
      query: {
        noCache: 'true',
      },
    })
  }

  addItem({ files, product, name, keywords }) {
    return this.http.put('store-item', {
      body: files,
      headers: {
        'Pix-Product': product,
        'Content-Type': 'application/octet-stream',
      },
      query: {
        name,
        keywords,
      },
    })
  }

  shareItem({ id }) {
    return this.http.post(`store-item/${id}/share`)
  }

  fetchItem({ id }, { product }) {
    return this.http.get(`store-item/${id}`, {
      headers: {
        'Pix-Product': product,
      },
    })
  }

  updateItem({ id, changes }, { product }) {
    return this.http.patch(`store-item/${id}`, {
      body: changes,
      headers: {
        'Pix-Product': product,
      },
    })
  }

  deleteItem({ id }) {
    return this.http.delete(`store-item/${id}`)
  }

  getStoreItemImage({ id }, { product }) {
    return this.http
      .get(`store-item/${id}/preview`, {
        headers: {
          'Content-Type': 'image/png',
          'Pix-Product': product,
        },
        query: {
          noCache: 'true',
        },
      })
      .then(response => response.blob())
      .then(readUploadedImgAsBase64)
  }
}
export default Market
