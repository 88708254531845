export default {
  '400.1': 'Invalid data syntax.',
  '400.2': 'Invalid data schema.',
  '400.2.1': 'Password too short.',
  '400.2.2': 'Password too weak.',
  '400.3': 'Inconsistent data.',
  '400.4': 'Unsupported data format.',

  '401': 'Unautorized.',
  '401.1.1': 'Token is missing.',
  '401.1.2': 'Invalid token format.',
  '401.1.3': 'Invalid token signature.',
  '401.1.4': 'Token expired.',
  '401.2.1': 'Invalid credentials or user does not exist.',
  '401.3.1': 'Auth token rejected by provider.',

  '403.1': 'Insufficient access.',

  '404': 'Not found.',
  '404.1': 'Entity with the specified id does not exist.',
  '404.1.1': 'Email not registered.',

  '409.1': 'Entity with the specified id already exist.',
  '409.1.1': 'Email already registered.',

  '429': 'Too many requests.',

  '500.1': 'Unknown error.',

  '501': 'Not implemented.',
  '503': 'Maintenance.',
}
