import { GET_LIST, CREATE, DELETE } from 'react-admin'

function createFirmwareProvider(api, headerParams) {
  const requestTypeMap = {
    [GET_LIST]: () => api.fetchAll(headerParams),
    [CREATE]: api.upload,
    [DELETE]: api.delete,
  }
  return function firmwareProvider(type, params) {
    const handler = requestTypeMap[type].bind(api)
    return handler(params)
  }
}

export default createFirmwareProvider
