import React from 'react'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import { Button } from '@material-ui/core'

const styles = () => ({
  firmwareActionBar: {
    padding: '10px',
    width: '100%',
    '& > button:not(:last-of-type)': {
      marginRight: '10px',
    },
  },
})

const ActionBar = props => {
  const { classes, productType, history, resourceTag } = props

  return (
    <div className={classes.firmwareActionBar}>
      <Button
        variant="contained"
        color={`${productType === 'original' && 'primary'}`}
        onClick={() => history.push(`${resourceTag}-original`)}
      >
        Pix Original
      </Button>
      <Button
        variant="contained"
        color={`${productType === 'mini' && 'primary'}`}
        onClick={() => history.push(`${resourceTag}-mini`)}
      >
        Pix Mini
      </Button>
    </div>
  )
}

export default withRouter(withStyles(styles)(ActionBar))
