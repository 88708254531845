import * as DefaultEndpoints from './endpoints'
import JsonHttp from './JsonHttp'

const DEFAULT_ENDPOINTS = {
  Auth: DefaultEndpoints.AuthEndpoint,
  Firmware: DefaultEndpoints.FirmwareEndpoint,
  Market: DefaultEndpoints.MarketEndpoint,
}

class Api {
  constructor({ baseUrl, endpoints }) {
    this.http = new JsonHttp({ baseUrl })
    this.baseUrl = baseUrl
    this.initApiEndpoints(endpoints)
  }

  initApiEndpoints(endpoints = {}) {
    const Endpoints = {
      ...DEFAULT_ENDPOINTS,
      ...endpoints,
    }
    const config = {
      http: this.http,
    }
    this.auth = new Endpoints.Auth(config)
    this.firmware = new Endpoints.Firmware(config)
    this.market = new Endpoints.Market(config)
  }
}

export default Api
