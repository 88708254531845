import React from 'react'
import PropTypes from 'prop-types'
import { withDataProvider, UPDATE, showNotification } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import { Modal, Grid, Button, CircularProgress } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const styles = theme => ({
  modal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30% !important',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  titleContainer: {
    textAlign: 'center',
  },
  title: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  inputContiner: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    height: 'auto',
    width: '60%',
    color: '#EFA544',
  },
  messageText: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '50px',
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const MarketHideModal = props => {
  const {
    classes,
    isOpen,
    handleClose,
    itemData,
    isLoading,
    dispatch,
    dataProvider,
  } = props

  const handleChangeHideState = () => {
    dataProvider(UPDATE, 'store-item', {
      id: itemData.id,
      changes: {
        deleted: !itemData.deleted,
      },
    })
      .then(() => {
        dispatch(
          showNotification(
            `Item successfully is ${itemData.deleted ? 'unhide' : 'hidden'}`,
            'info',
          ),
        )
        handleClose()
      })
      .catch(e => {
        dispatch(
          showNotification(
            `Error: Item is not ${
              itemData.deleted ? 'unhide' : 'hidden'
            }. ${e}`,
            'warning',
          ),
        )
        handleClose()
      })
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classes.modal}>
        <Grid item xs={12} className={classes.titleContainer}>
          <span className={classes.title}>{`Are you sure you want to ${
            itemData.deleted ? 'unhide' : 'hide'
          } ${itemData.name}?`}</span>
        </Grid>
        <br />
        <div className={classes.iconContainer}>
          {itemData.deleted ? (
            <Visibility className={classes.icon} />
          ) : (
            <VisibilityOff className={classes.icon} />
          )}
        </div>
        <br />
        <div className={classes.messageText}>
          {`
              It will ${
                itemData.deleted ? 'be shown in' : 'disappear from'
              } users markets.
              It will be possible to make it ${
                itemData.deleted ? 'unavailable' : 'available'
              }
              again in Hidden content section.
            `}
        </div>
        <Grid item xs={12} container justify="space-between">
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangeHideState}
            disabled={isLoading}
          >
            {`${itemData.deleted ? 'Unhide' : 'Hide'}`}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Grid>
      </div>
    </Modal>
  )
}

MarketHideModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  itemData: PropTypes.object.isRequired,
}

export default withDataProvider(withStyles(styles)(MarketHideModal))
