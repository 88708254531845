import React, { createElement } from 'react'
import { connect } from 'react-redux'
import { MenuItemLink, getResources } from 'react-admin'
import { withRouter } from 'react-router-dom'
import LabelIcon from '@material-ui/icons/Label'

const Menu = ({ resources, onMenuClick }) => {
  const filteredResources = resources.filter(
    item => item.options && !item.options.hide,
  )

  return (
    <div>
      {filteredResources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          leftIcon={
            resource.icon ? createElement(resource.icon) : <LabelIcon />
          }
          onClick={onMenuClick}
        />
      ))}
    </div>
  )
}

const mapStateToProps = state => ({
  resources: getResources(state),
})

export default withRouter(connect(mapStateToProps)(Menu))
