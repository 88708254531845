const marketItemSize = {
  width: 110,
  height: 190,
  borderWidth: 1,
  margin: 10,
}

const getColsForWidth = (width, marketItemSize) => {
  if (!width || !marketItemSize) {
    return 0
  }
  const edgeCount = 2 // number 2 means use left margin and border width and right margin and border width into calculations
  const marketItemWidth =
    marketItemSize.width +
    (marketItemSize.borderWidth + marketItemSize.margin) * edgeCount

  const columnsCount = parseInt(width / marketItemWidth)

  return columnsCount
}

const paginationEngine = (data, page, perPage) => {
  const startNumberItem = (page - 1) * perPage
  const endNumberItem =
    startNumberItem + perPage > data.length
      ? data.lengths
      : startNumberItem + perPage

  return data.slice(startNumberItem, endNumberItem)
}

const filterItemsByCategory = (category, itemsIdArray, itemsData) => {
  let tempItemsIdArray = [...itemsIdArray]

  if (category === 'hidden') {
    tempItemsIdArray = tempItemsIdArray.filter(
      itemId => itemsData[itemId] && itemsData[itemId].deleted === true,
    )
  } else {
    tempItemsIdArray = tempItemsIdArray.filter(
      itemId => itemsData[itemId] && itemsData[itemId].category === category,
    )
  }
  return tempItemsIdArray
}

const filterItemsBySearchString = (search, itemsIdArray, itemsData) => {
  let tempItemsIdArray = [...itemsIdArray]
  const keywords = search.split(',')

  tempItemsIdArray = tempItemsIdArray.filter(itemId => {
    let isMatch = false

    keywords.forEach(keyword => {
      if (
        itemsData[itemId] &&
        itemsData[itemId].keywords
          .toLowerCase()
          .includes(keyword.trim().toLowerCase())
      )
        isMatch = true
      if (
        itemsData[itemId] &&
        itemsData[itemId].name
          .toLowerCase()
          .includes(keyword.trim().toLowerCase())
      )
        isMatch = true
    })

    return isMatch
  })
  return tempItemsIdArray
}

export {
  getColsForWidth,
  paginationEngine,
  filterItemsByCategory,
  filterItemsBySearchString,
  marketItemSize,
}
