import React, { useState } from 'react'

import { withStyles } from '@material-ui/core'
import { Button } from '@material-ui/core'

import AddFirmwareModal from './AddFirmwareModal'

const styles = () => ({
  firmwareActionBar: {
    paddingTop: '20px',
    width: '100%',
    '& > button:not(:last-of-type)': {
      marginRight: '10px',
    },
  },
})

const AddFirmware = props => {
  const { classes, productType } = props

  const [uploadModalOpened, setUploadModalOpened] = useState(false)

  const handleOpenUploadModal = () => {
    setUploadModalOpened(true)
  }

  const handleCloseUploadModal = () => {
    setUploadModalOpened(false)
  }

  return (
    <div className={classes.firmwareActionBar}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenUploadModal}
      >
        New Firmware
      </Button>
      {uploadModalOpened && (
        <AddFirmwareModal
          {...props}
          isOpen={uploadModalOpened}
          handleClose={handleCloseUploadModal}
          product={productType}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(AddFirmware)
