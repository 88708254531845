import React from 'react'
import { Admin } from 'react-admin'
import Api from './api'
import dataProvider, {
  authProvider,
  FormattedFirmwareEndpoint,
  FormattedMarketEndpoint,
} from './reactAdmin'
import {
  FirmwareMiniResource,
  FirmwareOriginalResource,
  MarketMiniResource,
  MarketOriginalResource,
} from './resources'
import Layout from './layout'
import Menu from './Menu'

const endpoints = {
  Firmware: FormattedFirmwareEndpoint,
  Market: FormattedMarketEndpoint,
}

const api = new Api({ baseUrl: process.env.REACT_APP_API_BASE_URL, endpoints })
const config = { logging: process.env.NODE_ENV !== 'production' }

function App() {
  return (
    <Admin
      title={process.env.REACT_APP_TITLE || 'Pix Admin Panel'}
      dataProvider={dataProvider(api, config)}
      authProvider={authProvider(api.auth, config)}
      appLayout={Layout}
      menu={Menu}
    >
      <MarketOriginalResource name="store-item-original" />
      <MarketMiniResource name="store-item-mini" />
      <FirmwareOriginalResource name="firmware-original" />
      <FirmwareMiniResource name="firmware-mini" />
    </Admin>
  )
}

export default App
