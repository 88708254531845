import React, { useState, useEffect } from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { showNotification, CREATE, withDataProvider } from 'react-admin'
import semver from 'semver'

import { Info } from '@material-ui/icons'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import {
  Modal,
  Grid,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Typography,
} from '@material-ui/core'

import DropZone from '../shared/DropZone'
import { capitalizeWord } from '../shared/helpers'

const styles = theme => ({
  modal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50% !important',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  inputContiner: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  warningButton: {
    marginLeft: '10px',
    color: '#fc0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  popoverMessage: {
    padding: '10px',
  },
  warningIconContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
  },
})

const isCurrentVersionHigher = (version, firmwareListData) => {
  if (!semver.valid(version)) {
    return false
  }
  const versionsArray = Object.keys(firmwareListData).map(
    firmware => firmwareListData[firmware].version,
  )
  if (versionsArray.length === 0) return true

  const maxVersion = semver.maxSatisfying(versionsArray, '*.*.*')
  return semver.gtr(version, maxVersion)
}

const AddFirmwareModal = props => {
  const {
    classes,
    isOpen,
    handleClose,
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    isLoading,
    product,
    data,
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const popoverOpen = Boolean(anchorEl)

  const closePopover = () => {
    setAnchorEl(null)
  }

  const openPopover = e => {
    setAnchorEl(e.currentTarget)
  }

  const showVersionWarning = semver.valid(values.version)
    ? !isCurrentVersionHigher(values.version, data)
    : false

  useEffect(() => {
    setFieldValue(
      'currentVersionHigher',
      isCurrentVersionHigher(values.version, data),
      true,
    )
  }, [values.version])

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classes.modal}>
        <Grid item xs={12}>
          <span className={classes.title}>
            {`Upload New Pix ${capitalizeWord(product)} Firmware`}
          </span>
        </Grid>
        <Grid item container xs={12} wrap="nowrap">
          <Grid item xs={8}>
            <div className={classes.inputContiner}>
              <TextField
                label="Version"
                name="version"
                margin="normal"
                value={values.name}
                onChange={handleChange('version')}
                error={
                  (touched.version && errors.version) ||
                  (touched.currentVersionHigher && errors.currentVersionHigher)
                }
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            {showVersionWarning && (
              <div className={classes.warningIconContainer}>
                <IconButton
                  className={classes.warningButton}
                  aria-haspopup="true"
                  onClick={openPopover}
                >
                  <Info />
                </IconButton>
                <Popover
                  id="preview-info"
                  open={popoverOpen}
                  anchorEl={anchorEl}
                  onClose={closePopover}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className={classes.popoverMessage}>
                    Version should be greater than the latest version of the
                    file
                  </Typography>
                </Popover>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <DropZone
            product={values.product}
            fileDescription="(Only firmware file)"
            files={values.files}
            setFiles={handleChange('files')}
            error={touched.files && errors.files}
          />
          <br />
        </Grid>
        <Grid item xs={12} container justify="space-between">
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Upload
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Grid>
      </div>
    </Modal>
  )
}

const AddFirmwareModalCompose = compose(
  withStyles(styles),
  withFormik({
    mapPropsToValues: () => ({
      version: '',
      files: [],
      currentVersionHigher: false,
    }),

    validationSchema: Yup.object().shape({
      currentVersionHigher: Yup.boolean().oneOf([true]),
      version: Yup.string()
        .test('is-version', 'Value should be a version', semver.valid)
        .required(),
      files: Yup.array()
        .min(1, true)
        .of(Yup.mixed().test('isValid', true, file => file.isValid)),
    }),

    handleSubmit: async (values, { props }) => {
      const { dataProvider, handleClose, dispatch } = props
      const payload = Object.assign({}, values, {
        files: values.files[0],
        product: props.product,
      })

      dataProvider(CREATE, props.resource, payload, {
        onSuccess: {
          refresh: true,
        },
      })
        .then(() => {
          dispatch(showNotification('Firmware uploaded successfully', 'info'))
          handleClose()
        })
        .catch(e => {
          dispatch(
            showNotification(
              `Error: Firmware is not uploaded. ${e}`,
              'warning',
            ),
          )
          handleClose()
        })
    },
  }),
)

export default withDataProvider(AddFirmwareModalCompose(AddFirmwareModal))
