import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { showNotification, CREATE, withDataProvider } from 'react-admin'

import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import {
  Modal,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core'

import DropZone from '../shared/DropZone'

const styles = theme => ({
  modal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50% !important',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  inputContiner: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const UploadModal = props => {
  const {
    classes,
    isOpen,
    handleClose,
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    isLoading,
  } = props

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classes.modal}>
        <Grid item xs={12}>
          <span className={classes.title}>Upload New Content</span>
        </Grid>
        <Grid item container xs={12} wrap="nowrap">
          <Grid item xs={8}>
            <div className={classes.inputContiner}>
              <TextField
                label="Name (will appear in Market)"
                name="name"
                margin="normal"
                value={values.name}
                onChange={handleChange('name')}
                error={touched.name && errors.name}
              />
              <TextField
                label="Keywords (will appear in Market)"
                name="keywords"
                margin="normal"
                value={values.keywords}
                onChange={handleChange('keywords')}
                error={touched.keywords && errors.keywords}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <DropZone
            product={values.product}
            fileDescription="(Only *.gif and *.png images will be accepted)"
            files={values.files}
            setFiles={handleChange('files')}
            error={touched.files && errors.files}
            fileType="picture"
          />
          <br />
        </Grid>
        <Grid item xs={12} container justify="space-between">
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Upload
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Grid>
      </div>
    </Modal>
  )
}

const MarketUploadModal = compose(
  withStyles(styles),
  withFormik({
    mapPropsToValues: props => {
      const productPreset = props.resource.includes('mini')
        ? 'mini'
        : 'original'
      return {
        name: '',
        keywords: '',
        files: [],
        product: productPreset,
      }
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required(true),
      keywords: Yup.string().required(true),
      files: Yup.array()
        .min(1, true)
        .of(Yup.mixed().test('isValid', true, file => file.isValid)),
    }),

    handleSubmit: async (values, { props }) => {
      const { dataProvider, handleClose, dispatch, resource } = props

      const payload = Object.assign({}, values, {
        files: values.files[0],
      })

      dataProvider(CREATE, resource, payload, {
        onSuccess: {
          refresh: true,
        },
      })
        .then(() => {
          dispatch(showNotification('Item added successfully', 'info'))
          handleClose()
        })
        .catch(e => {
          dispatch(
            showNotification(`Error: Item is not added. ${e}`, 'warning'),
          )
          handleClose()
        })
    },
  }),
)

export default withDataProvider(MarketUploadModal(UploadModal))
