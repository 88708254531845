import React from 'react'
import { Resource } from 'react-admin'
import marketIcon from '@material-ui/icons/Shop'

import MarketItemsList from './components/MarketItemsList'

function MarketMini(props) {
  return (
    <Resource
      {...props}
      list={MarketItemsList}
      icon={marketIcon}
      options={{ hide: true, productType: 'mini' }}
    />
  )
}

export default MarketMini
