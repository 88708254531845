import React, { useEffect } from 'react'

import {
  GridListTile,
  GridList as MuiGridList,
  TextField,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormControl,
  Button,
  withStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { getColsForWidth, marketItemSize } from './helpers'

import styles from './styles'

const placeholder = (nbChildren, fn) =>
  Array.from({ length: nbChildren }, (_, key) => fn(`placeholder_${key}`))

const LoadingItemsList = props => {
  const { classes, perPage, setCardContainerWidth, cardContainerWidth } = props

  useEffect(() => {
    window.addEventListener('resize', resize)
    resize()
  }, [])

  useEffect(
    () => () => {
      window.removeEventListener('resize', resize)
    },
    [],
  )

  const resize = () => {
    const conteinerWidth = document.getElementById('rootContainer')
    setCardContainerWidth(conteinerWidth.offsetWidth)
  }

  return (
    <div className={classes.root}>
      <div className={classes.actionBar} id="rootContainer">
        <div className={classes.actionInputs}>
          <TextField
            label="Search (by name, keywords)"
            margin="normal"
            className={classes.searchInput}
          />
          <FormControl>
            <InputLabel htmlFor="category-helper">Category</InputLabel>
            <Select
              className={classes.categorySelect}
              input={<Input value="" />}
            >
              <MenuItem>
                <em>None</em>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <Button variant="outlined" disable={'true'}>
            <AddIcon />
            UPLOAD
          </Button>
        </div>
      </div>
      <MuiGridList
        cellHeight="auto"
        cols={getColsForWidth(cardContainerWidth, marketItemSize)}
        className={classes.gridLists}
      >
        {placeholder(perPage, key => (
          <GridListTile key={key}>
            <div className={classes.placeholder} />
          </GridListTile>
        ))}
      </MuiGridList>
    </div>
  )
}

export default withStyles(styles)(LoadingItemsList)
