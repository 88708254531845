import React, { useEffect } from 'react'
import { Datagrid, TextField } from 'react-admin'
import { withRouter } from 'react-router-dom'

import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton, withStyles } from '@material-ui/core'

const styles = () => ({
  firmwareActionBar: {
    padding: '10px',
    width: '100%',
    '& > button:not(:last-of-type)': {
      marginRight: '10px',
    },
  },
  tableRow: {
    padding: '2px',
    '& thead, tbody, th, td': {
      border: '1px solid rgba(0, 0, 0, 0.17)',
    },
    '& > tbody > tr > td': {
      textAlign: 'center',
    },
    '& > thead > tr > th': {
      textAlign: 'center',
    },
  },
})

const DeleteButton = props => {
  const { record, handleOpenDeleteModal } = props
  return (
    <IconButton onClick={() => handleOpenDeleteModal(record)}>
      <DeleteIcon />
    </IconButton>
  )
}

const FirmwareDatagrid = props => {
  const { classes, handleOpenDeleteModal, setLoading, isLoading } = props

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading, setLoading])

  return (
    <Datagrid
      {...props}
      className={classes.tableRow}
      hasBulkActions={false}
      hover={false}
    >
      <TextField source="version" />
      <TextField source="md5" />
      <DeleteButton handleOpenDeleteModal={handleOpenDeleteModal} />
    </Datagrid>
  )
}

export default withRouter(withStyles(styles)(FirmwareDatagrid))
