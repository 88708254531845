import React from 'react'

import { withStyles } from '@material-ui/core'

import LoadedItemsList from './LoadedItemsList'
import LoadingItemsList from './LoadingItemsList'
import { getColsForWidth, marketItemSize } from './helpers'

import styles from './styles'

const initialListRowsPerPage = 4

class MarketItemsContainer extends React.Component {
  state = {
    page: 1,
    perPage: 10,
    search: '',
    category: '',
    cardContainerWidth: 0,
  }

  handleChange = e => {
    const { name, value } = e.target

    this.setState({
      [name]: value,
    })
  }

  setCardContainerWidth = width => {
    this.setState({
      cardContainerWidth: width,
      perPage: getColsForWidth(width, marketItemSize) * initialListRowsPerPage,
    })
  }

  render() {
    const { loadedOnce } = this.props
    const { page, perPage, search, category, cardContainerWidth } = this.state

    return loadedOnce ? (
      <LoadedItemsList
        {...this.props}
        page={page}
        perPage={perPage}
        search={search}
        category={category}
        setPaginationPage={page => this.setState({ page })}
        setPaginationPerPage={perPage => this.setState({ perPage })}
        setSearch={this.handleChange}
        setCategory={this.handleChange}
        setCardContainerWidth={this.setCardContainerWidth}
        cardContainerWidth={cardContainerWidth}
      />
    ) : (
      <LoadingItemsList
        {...this.props}
        perPage={perPage}
        setCardContainerWidth={this.setCardContainerWidth}
        cardContainerWidth={cardContainerWidth}
      />
    )
  }
}

export default withStyles(styles)(MarketItemsContainer)
