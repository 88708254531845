import React from 'react'

import { IconButton } from '@material-ui/core'
import { Delete, AttachFile } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

const FilePreviewContainer = props => {
  const { classes, files, removeFile } = props

  return (
    <div className={classes.firmwareItemContaiter}>
      {files.map((file, index) => (
        <div
          className={classes.uploadFirmwareItem}
          key={`upload_firmware_${index}`}
        >
          <AttachFile />
          {file.name}
          <IconButton
            onClick={() => removeFile(index)}
            className={classes.firmwareFileDeteteButton}
          >
            <Delete />
          </IconButton>
        </div>
      ))}
    </div>
  )
}

export default withStyles(styles)(FilePreviewContainer)
