import Endpoint from './Endpoint'

class Auth extends Endpoint {
  async signIn({ username, password }) {
    const body = { setCookie: true, username, password }
    const response = await this.http.post('local-membership/auth', { body })

    if (response.accessToken) {
      localStorage.setItem('access_token', response.accessToken)
    }
    return response
  }

  signOut() {
    localStorage.removeItem('access_token')
    return this.http.post('sign-out')
  }

  async checkAccess() {
    return await this.http.post('check-access')
  }
}

export default Auth
