class ImageValidation {
  constructor(imgFile = {}, product = 'original') {
    this.imgFile = imgFile
    this.product = product
    this.productTypes = {
      original: {
        height: 20,
        width: 16,
      },
      mini: {
        height: 16,
        width: 16,
      },
    }
    this.availableTypes = ['image/png', 'image/gif']
  }

  isValid() {
    return new Promise((resolve, reject) => {
      try {
        const fr = new FileReader()
        let isValid = true
        let errorMessage = ''

        fr.onload = () => {
          const img = new Image()
          const { product, productTypes, imgFile, availableTypes } = this
          const fileType = imgFile.type

          img.src = fr.result
          img.onload = () => {
            if (
              productTypes[product].height !== img.height ||
              productTypes[product].width !== img.width
            ) {
              errorMessage = `Image size is not valid, you should use a ${productTypes[product].height}x${productTypes[product].width} image.`
            }

            if (availableTypes.indexOf(fileType) === -1) {
              errorMessage = `Image type is not valid, you should use only *.gif and *.png images.`
            }

            if (errorMessage) {
              isValid = false
            }
            resolve({
              isValid,
              errorMessage,
            })
          }
        }
        fr.readAsDataURL(this.imgFile)
      } catch {
        reject({
          isValid: false,
          errorMessage: `Can't read image file`,
        })
      }
    })
  }
}

class FirmwareValidation {
  constructor(file = {}) {
    this.file = file
    this.availableTypes = ['.bin']
  }

  isValid() {
    return new Promise((resolve, reject) => {
      try {
        const fr = new FileReader()
        let isValid = true
        let errorMessage = ''

        fr.onload = () => {
          resolve({
            isValid,
            errorMessage,
          })
        }
        fr.readAsBinaryString(this.file)
      } catch {
        reject({
          isValid: false,
          errorMessage: `Can't upload file`,
        })
      }
    })
  }
}

const capitalizeWord = word => {
  if (word && typeof word === 'string') {
    return word.charAt(0).toUpperCase() + word.slice(1)
  } else {
    return word
  }
}

export { ImageValidation, FirmwareValidation, capitalizeWord }
