import { createFirmwareProvider, createStoreItemProvider } from './providers'

/**
 * Query a data provider and return a promise for a response
 * https://marmelab.com/react-admin/DataProviders.html#writing-your-own-data-provider
 *
 * @example
 * dataProvider(GET_ONE, 'posts', { id: 123 })
 *  => Promise.resolve({ data: { id: 123, title: "hello, world" } })
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the action type
 * @returns {Promise} the Promise for a response
 */

function createDataProvider(api, { logging = false } = {}) {
  const providers = {
    'firmware-original': createFirmwareProvider(api.firmware, {
      product: 'original',
    }),
    'firmware-mini': createFirmwareProvider(api.firmware, { product: 'mini' }),
    'store-item-original': createStoreItemProvider(api.market, {
      product: 'original',
    }),
    'store-item-mini': createStoreItemProvider(api.market, { product: 'mini' }),
  }
  return function dataProvider(type, resource, params) {
    if (logging) {
      console.info({ type, resource, params })
    }
    const provider = providers[resource]
    return provider(type, params)
  }
}

export default createDataProvider
