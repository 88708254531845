import React, { Fragment, useState } from 'react'
import { List } from 'react-admin'

import { withStyles } from '@material-ui/core'

import ActionBar from '../shared/ActionBar'
import FirmwareDatagrid from './FirmwareDatagrid'
import AddFirmware from './AddFirmware'

import DeleteFirmwareModal from '../shared/DeleteModal'

const styles = () => ({
  firmwareList: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
})

const FirmwareList = props => {
  const { options, classes } = props
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const [itemData, setItemData] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOpenDeleteModal = itemData => {
    setDeleteModalOpened(true)
    const deleteFirmwareData = Object.assign(itemData, {
      product: options.productType,
    })
    setItemData(deleteFirmwareData)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpened(false)
  }

  return (
    <Fragment>
      <ActionBar productType={options.productType} resourceTag="firmware" />
      <List
        {...props}
        exporter={false}
        pagination={null}
        sortable={false}
        actions={null}
        className={classes.firmwareList}
        aside={<AddFirmware {...props} productType={options.productType} />}
      >
        <FirmwareDatagrid
          handleOpenDeleteModal={handleOpenDeleteModal}
          setLoading={setLoading}
        />
      </List>
      {deleteModalOpened && (
        <DeleteFirmwareModal
          isOpen={deleteModalOpened}
          handleClose={handleCloseDeleteModal}
          itemData={itemData}
          isLoading={loading}
          titleText={`Are you sure you want to delete firmware ${itemData.version}?`}
          resource={props.resource}
        />
      )}
    </Fragment>
  )
}

export default withStyles(styles)(FirmwareList)
