import React from 'react'
import { Resource } from 'react-admin'

import FirmwareList from './components/Firmware/FirmwareList'

function FirmwareMini(props) {
  return (
    <Resource
      {...props}
      list={FirmwareList}
      options={{ hide: true, productType: 'mini' }}
    />
  )
}

export default FirmwareMini
