import { marketItemSize } from './helpers'

export default {
  root: {
    padding: '10px',
  },
  gridList: {
    width: '100%',
    margin: '0 !important',
  },
  gridListPlaceholder: {
    width: '100%',
    margin: '0 !important',
    justifyContent: 'space-between',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 !important',
  },
  placeholder: {
    backgroundColor: '#E0E0E0',
    width: `${marketItemSize.width}px`,
    height: `${marketItemSize.height}px`,
    border: `${marketItemSize.borderWidth}px solid #929eaa4f`,
    margin: `${marketItemSize.margin}px`,
  },
  searchInput: {
    minWidth: '250px',
    marginRight: '20px',
  },
  categorySelect: {
    minWidth: '150px',
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  card: {
    transition: '0.3s',
    width: `${marketItemSize.width}px`,
    height: `${marketItemSize.height}px`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
    border: `${marketItemSize.borderWidth}px solid #929eaa4f`,
    boxShadow: 'none',
    margin: `${marketItemSize.margin}px`,
  },
  content: {
    height: '100%',
    padding: '5px !important',
  },
  media: {
    transition: '0.3s',
    height: '100%',
    borderRadius: '4px',
    backgroundSize: 'contain',
  },
  headeText: {
    padding: '5px 15px 0 10px',
    '& > div > span': {
      fontSize: '14px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: `${marketItemSize.width - 50}px`,
    },
  },
  imageProgress: {
    color: '#2196f3',
    position: 'absolute',
    height: '200px',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}
