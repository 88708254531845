import { MarketEndpoint } from 'api/endpoints'

class FormattedMarketEndpoint extends MarketEndpoint {
  async fetchAllItems(params) {
    const response = await super.fetchAllItems(params)
    return {
      total: response.storeItems.length,
      data: response.storeItems.reverse(), // <- reverse to show the newest items first
    }
  }

  async addItem(params, headerParams) {
    const addItemResponse = await super.addItem(params, headerParams)
    await super.shareItem(addItemResponse)
    const fetchItemsResponse = await super.fetchItem(
      addItemResponse,
      headerParams,
    )
    return {
      data: fetchItemsResponse,
    }
  }

  async deleteItem(params, headerParams) {
    await super.deleteItem(params, headerParams)
    return {
      data: params,
    }
  }

  async updateItem(params, headerParams) {
    await super.updateItem(params, headerParams)
    const fetchItemsResponse = await super.fetchItem(params, headerParams)
    return {
      data: fetchItemsResponse,
    }
  }

  async getStoreItemImage(item, headerParams) {
    const imgURL = await super.getStoreItemImage(item, headerParams)
    return {
      data: Object.assign(item, { preview: imgURL }),
    }
  }
}

export default FormattedMarketEndpoint
