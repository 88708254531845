const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '300px',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  previewContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewDeteteButton: {
    position: 'absolute',
    right: '-10px',
    bottom: '-6px',
    color: '#2196f3',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  previewInfoButton: {
    position: 'absolute',
    left: '-10px',
    bottom: '-6px',
    color: 'red',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  imagePreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'border .24s ease-in-out',
  },
  imagePreview: {
    height: '300px',
    borderRadius: '4px',
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    paddingBottom: '3px !important',
  },
  cardContent: {
    margin: '5px',
    position: 'relative',
  },
  errorStyle: {
    border: '2px solid red',
  },
  popoverMessage: {
    margin: '20px',
  },
  descriptionContainer: {
    textAlign: 'center',
  },
  uploadFirmwareItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  firmwareFileDeteteButton: {
    left: '10px',
    color: 'red',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  firmwareItemContaiter: {
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '200px',
  },
}

export default styles
