import React, { Fragment, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'

import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { ImageValidation, FirmwareValidation } from './helpers'
import ImagePreviewContainer from './ImagePreviewContainer'
import FilePreviewContainer from './FilePreviewContainer'

import styles from './styles'

const DropZone = props => {
  const {
    classes,
    product,
    fileDescription,
    files,
    setFiles,
    error,
    fileType,
  } = props
  const isPicture = fileType === 'picture'
  let availableTypes

  if (isPicture) {
    availableTypes = new ImageValidation().availableTypes
  } else {
    availableTypes = new FirmwareValidation().availableTypes
  }

  const { getRootProps, open, getInputProps } = useDropzone({
    accept: availableTypes.join(','),
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file => {
          if (isPicture) {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          } else {
            return file
          }
        }),
      )
    },
  })

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (isPicture) {
        files.forEach(file => URL.revokeObjectURL(file.preview))
      }
    },
    [files.length],
  )

  useEffect(() => {
    if (!isPicture) {
      files.forEach((_, index) => checkFiles(index))
    }
  }, [files.length])

  const checkFiles = index => {
    const tempFiles = [...files]
    let filesValidation

    if (isPicture) {
      filesValidation = new ImageValidation(tempFiles[index], product)
    } else {
      filesValidation = new FirmwareValidation(tempFiles[index])
    }

    filesValidation.isValid().then(result => {
      Object.assign(tempFiles[index], {
        isValid: result.isValid,
        validationMessage: result.errorMessage,
      })
      setFiles(tempFiles)
    })
  }

  const removeFile = index => {
    const tempFiles = [...files]

    tempFiles.splice(index, 1)
    setFiles(tempFiles)
  }

  return (
    <div>
      {files.length > 0 ? (
        <Fragment>
          {isPicture ? (
            <ImagePreviewContainer
              {...props}
              availableTypes={availableTypes}
              checkImgParameters={checkFiles}
              removeFile={removeFile}
            />
          ) : (
            <FilePreviewContainer removeFile={removeFile} {...props} />
          )}
        </Fragment>
      ) : (
        <div
          {...getRootProps()}
          className={
            error
              ? `${classes.container} ${classes.errorStyle}`
              : classes.container
          }
        >
          <input {...getInputProps()} />
          <div className={classes.descriptionContainer}>
            <p>Drag 'n' drop some files here, or click to select files</p>
            <em>{fileDescription}</em>
          </div>
          <br />
          <Button variant="contained" color="primary" onClick={open}>
            Upload
          </Button>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(DropZone)
