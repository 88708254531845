import { FirmwareEndpoint } from 'api/endpoints'

class FormattedFirmwareEndpoint extends FirmwareEndpoint {
  async fetchAll(params) {
    const response = await super.fetchAll(params)
    return {
      total: response.list.length,
      data: response.list.map(firmware => ({
        id: firmware.version,
        ...firmware,
      })),
    }
  }

  async upload(params) {
    await super.upload(params)
    return {
      data: {
        id: params.version,
        version: params.version, // <- simulate return data, need return firmware data
      },
    }
  }

  async delete(params) {
    await super.delete(params)
    return {
      data: {
        id: params.version, // <- simulate return data, need return firmware data
      },
    }
  }
}

export default FormattedFirmwareEndpoint
