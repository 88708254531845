import React from 'react'
import { AppBar } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
}

const CustomAppBar = ({ classes, ...props }) => (
  <AppBar {...props}>
    <h2 className={classes.title}>{props.title}</h2>
    <span className={classes.spacer} />
  </AppBar>
)

export default withStyles(styles)(CustomAppBar)
