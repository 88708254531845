import React from 'react'
import { Resource } from 'react-admin'
import marketIcon from '@material-ui/icons/Shop'

import MarketItemsList from './components/MarketItemsList'

function MarketOriginal(props) {
  return (
    <Resource
      {...props}
      list={MarketItemsList}
      icon={marketIcon}
      options={{ label: 'Market', productType: 'original' }}
    />
  )
}

export default MarketOriginal
