import React, { useState, useEffect } from 'react'
import { Pagination } from 'react-admin'

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Input,
  InputLabel,
  GridListTile,
  GridList as MuiGridList,
  Button,
  withStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import MarketItem from './MarketItem'
import MarketUploadModal from './MarketUploadModal'
import {
  getColsForWidth,
  paginationEngine,
  filterItemsByCategory,
  filterItemsBySearchString,
  marketItemSize,
} from './helpers'

import { capitalizeWord } from '../shared/helpers'
import styles from './styles'

const rowsPerPageCount = 5
const additionalOptionsRowsPerPage = [10, 20]

const ActionBar = props => {
  const {
    classes,
    search,
    setSearch,
    category,
    setCategory,
    itemsCategories,
    handleOpenUploadModal,
  } = props

  return (
    <div className={classes.actionBar}>
      <div className={classes.actionInputs}>
        <TextField
          label="Search (by name, keywords)"
          name="search"
          className={classes.searchInput}
          value={search}
          onChange={setSearch}
          margin="normal"
        />
        <FormControl>
          <InputLabel htmlFor="category-helper">Category</InputLabel>
          <Select
            name="category"
            value={category}
            onChange={setCategory}
            className={classes.categorySelect}
            input={<Input />}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {itemsCategories.map((category, key) => (
              <MenuItem value={category} key={`categoryElement_${key}`}>
                {capitalizeWord(category)}
              </MenuItem>
            ))}
            <MenuItem value="hidden">Hidden</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <Button variant="outlined" onClick={handleOpenUploadModal}>
          <AddIcon />
          UPLOAD
        </Button>
      </div>
    </div>
  )
}

const LoadedItemsList = props => {
  const {
    classes,
    ids,
    data,
    page,
    perPage,
    search,
    category,
    setPaginationPage,
    setPaginationPerPage,
    isLoading,
    setCardContainerWidth,
    cardContainerWidth,
  } = props
  const [uploadModalOpened, setUploadModalOpened] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', resize)
    resize()
  }, [])

  useEffect(
    () => () => {
      window.removeEventListener('resize', resize)
    },
    [],
  )

  const resize = () => {
    const conteinerWidth = document.getElementById('rootContainer')
    setCardContainerWidth(conteinerWidth.offsetWidth)
  }

  const handleOpenUploadModal = () => {
    setUploadModalOpened(true)
  }

  const handleCloseUploadModal = () => {
    setUploadModalOpened(false)
  }

  const getOptionsItemsPerPage = (
    n = 5,
    containerWidth,
    itemSize,
    additionalOptions = [],
  ) => {
    const colsForWidth = getColsForWidth(containerWidth, itemSize)
    const additionalOptionsArray = additionalOptions.map(
      rowsCount => colsForWidth * rowsCount,
    )

    const optionsList = Array(n)
      .fill(null)
      .map((_, i) => colsForWidth * (i + 1))
      .concat(additionalOptionsArray)

    return optionsList
  }

  const itemsData = Object.assign({}, data)

  Object.keys(itemsData).forEach(key => {
    if (!itemsData[key].deleted) {
      itemsData[key].deleted = false
    }
  })

  let itemsIdArray = [...ids]
  const itemsCategories = [
    ...new Set(Object.keys(itemsData).map(key => itemsData[key].category)),
  ]

  if (search) {
    itemsIdArray = filterItemsBySearchString(search, itemsIdArray, itemsData)
  }

  if (category) {
    itemsIdArray = filterItemsByCategory(category, itemsIdArray, itemsData)
  }

  return (
    <div className={classes.root}>
      <div id="rootContainer">
        <ActionBar
          {...props}
          itemsCategories={itemsCategories}
          handleOpenUploadModal={handleOpenUploadModal}
        />
        <MuiGridList
          cellHeight="auto"
          cols={getColsForWidth(cardContainerWidth, marketItemSize)}
          className={classes.gridList}
        >
          {paginationEngine(itemsIdArray, page, perPage).map((itemId, key) => (
            <GridListTile key={`itemKey_${key}`} className={classes.gridItem}>
              <MarketItem
                item={itemsData[itemId]}
                isLoading={isLoading}
                resource={props.resource}
              />
            </GridListTile>
          ))}
        </MuiGridList>
        <Pagination
          page={page}
          perPage={perPage}
          total={itemsIdArray.length}
          setPage={setPaginationPage}
          setPerPage={setPaginationPerPage}
          rowsPerPageOptions={getOptionsItemsPerPage(
            rowsPerPageCount,
            cardContainerWidth,
            marketItemSize,
            additionalOptionsRowsPerPage,
          )}
        />
        {uploadModalOpened && (
          <MarketUploadModal
            isOpen={uploadModalOpened}
            handleClose={handleCloseUploadModal}
            isLoading={isLoading}
            resource={props.resource}
          />
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(LoadedItemsList)
