import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { withDataProvider, showNotification, UPDATE } from 'react-admin'

import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import {
  Modal,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core'

const styles = theme => ({
  modal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50% !important',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  inputContiner: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const EditModal = props => {
  const {
    classes,
    isOpen,
    handleClose,
    values,
    errors,
    handleChange,
    handleSubmit,
    itemData,
    touched,
    isLoading,
  } = props

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classes.modal}>
        <Grid item xs={12}>
          <span className={classes.title}>{`Edit ${itemData.name}`}</span>
        </Grid>
        <Grid item container xs={12} wrap="nowrap">
          <Grid item xs={8}>
            <div className={classes.inputContiner}>
              <TextField
                label="Name (will appear in Market)"
                name="name"
                margin="normal"
                value={values.name}
                onChange={handleChange('name')}
                error={touched.name && errors.name}
              />
              <TextField
                label="Keywords (will appear in Market)"
                name="keywords"
                margin="normal"
                value={values.keywords}
                onChange={handleChange('keywords')}
                error={touched.keywords && errors.keywords}
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12} container justify="space-between">
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Save
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Grid>
      </div>
    </Modal>
  )
}

const MarketEditModal = compose(
  withStyles(styles),
  withFormik({
    mapPropsToValues: ({ itemData }) => ({
      name: itemData.name,
      keywords: itemData.keywords,
    }),

    validationSchema: Yup.object().shape({
      name: Yup.string().required(true),
      keywords: Yup.string().required(true),
    }),

    handleSubmit: async (values, { props }) => {
      const { dataProvider, handleClose, dispatch, itemData, resource } = props

      dataProvider(UPDATE, resource, {
        id: itemData.id,
        changes: values,
      })
        .then(() => {
          dispatch(showNotification('Item updated successfully', 'info'))
          handleClose()
        })
        .catch(e => {
          dispatch(
            showNotification(`Error: Item is not updated. ${e}`, 'warning'),
          )
          handleClose()
        })
    },
  }),
)

export default withDataProvider(MarketEditModal(EditModal))
